import {endpoint} from './endpoints';

export const retrieveBookings = () => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    }
    return fetch(endpoint, options)
        .then((response) => response.json())
}

export const retrieveBookingsByPage = (page) => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    }
    return fetch(endpoint+'?page='+page, options)
        .then((response) => response.json())
}
