import {useState, useEffect} from 'react';
import {retrieveBookings} from './constants/api';
import {Container, Grid, Stack, Box, Typography, Paper} from '@mui/material';
import BookingIndexItem from './IndexItem';
import BookingDataHeader from './DataHeader';
import BookingMonthDataHeader from './MonthDataHeader';
import BookingPagination from './Pagination';

const BookingIndex = () => {
    const [loaded, setLoaded] = useState(false);
    const [bookings, setBookings] = useState(undefined);
    const [pagination, setPagination] = useState(undefined);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            retrieveBookings().then((data) => {
                console.log(data);
                setBookings(data.results)
                setPagination({'count': data.count, 'pages': data.pages, 'next': data.next, 'previous': data.previous});
                setPage(data.page_number);
            })
        }
    }, [loaded, bookings]);

    return (<Container maxWidth="xs" key={"bic-page-"+page}>{(bookings !== undefined && Object.keys(bookings).length > 0) ? (<Stack>
            <BookingDataHeader pagination={pagination} />
            {Object.keys(bookings).map((booking_key, booking_key_index) => (<Stack key={"bis-"+page+"-"+booking_key_index}>
                <BookingMonthDataHeader booking_key={booking_key} booking_key_index={booking_key_index} page={page}  />

                <Box>
                    <Grid container justifyContent="flex-start" spacing={2}>
                        {bookings[booking_key].map((booking, booking_index) => (<BookingIndexItem key={"bi-"+page+"-"+booking_key_index+"-"+booking_index} booking={booking} />))}
                    </Grid>
                    </Box></Stack>))}
                    <BookingPagination pagination={pagination} setBookings={setBookings} />
    </Stack>):(<Paper sx={{p:4}}>
    <Typography variant="h5" align="center">No hay reservas para desplegar</Typography>
    </Paper>)}
    </Container>);
}
export default BookingIndex;
