import {Paper,Button,TextField,Link,Grid,Typography,Container} from '@mui/material';
import {sendSignIn} from '../constants/api/auth';
import {useSearchParams, useNavigate} from 'react-router-dom';
import Message from '../Message';
import logo from '../../../logo.png';

const Signin = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        sendSignIn(event).then((data) => {
            if(data.status === "success") {
                navigate('/bookings')
            } else if(data.status === "error") {
                navigate("?"+searchParams, {state:{message:data}});
            }
        });
    }
    return (
        <Container maxWidth="xs" align="center" sx={{mt:2}}>
            <Paper elevation={2} sx={{p:2}} component="form" noValidate onSubmit={handleSubmit}>
                <Message />
                <img alt="ChilW" src={logo} width="80%"/>
                <Typography variant="h5" sx={{mb:2}}>
                    Ingresar
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="false"
                        />
                            </Grid>
                        </Grid>
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                            Ingresar
                    </Button>
                    <Grid container justifyContent="center">
                        <Grid item xs={6}>
                            <Link href={"/auth/sign-up?"+searchParams} variant="body2">¿No tiene una cuenta?</Link>
                        </Grid>
                        <Grid item xs={6}>
                            <Link href={"/auth/password-forgot?"+searchParams} variant="body2">¿Olvidó su contraseña?</Link>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            );
}

export default Signin;
