import {Paper, List, ListItem, ListItemText} from '@mui/material';
import {minuteFormat} from '../../constants/functions';
import {weekDaysSpanish, monthsSpanish} from '../../constants/data';

const BookingIndexItemDetails = (props) => {
    return (
        <Paper sx={{ width:"50%", background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(238,238,238,1) 100%)' }}>
            <List disablePadding={true} dense={true}>
                <ListItem>
                    <ListItemText primary="Hora" secondary={props.booking.hour+":"+minuteFormat(props.booking.minute)} />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Fecha" secondary={weekDaysSpanish[props.booking.weekday]+" "+props.booking.day+" de "+monthsSpanish[props.booking.month-1]+" "+props.booking.year} />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Sector" secondary={props.booking.zone_name} />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Mesa" secondary={props.booking.agenda_name} />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Cantidad de Personas" secondary={props.booking.slots} />
                </ListItem>
            </List>
        </Paper>
        );
}

export default BookingIndexItemDetails;
