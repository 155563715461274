import Layout from './components/Layout';
import NotFound from './components/NotFound';
import BookingIndex from './components/Booking/Index';
import IconsLayout from './components/IconsLayout';
import Signup from './components/Auth/Signup/Signup';
import Signin from './components/Auth/Signin/Signin';
import Activate from './components/Auth/Activate/Activate';
import ActivateNewEmail from './components/Profile/ActivateNewEmail/ActivateNewEmail';
import ActivateNewPhone from './components/Profile/ActivateNewPhone/ActivateNewPhone';
import PasswordForgot from './components/Auth/PasswordForgot/PasswordForgot';
import PasswordForgotSent from './components/Auth/PasswordForgot/Sent';
import PasswordForgotChange from './components/Auth/PasswordForgot/Change';
import PasswordForgotModified from './components/Auth/PasswordForgot/Modified';
import ProfileIndex from './components/Profile/Index';
import ProfileNameEdit from './components/Profile/NameEdit';
import ProfileEmailEdit from './components/Profile/EmailEdit';
import ProfilePhoneEdit from './components/Profile/PhoneEdit';

import {
    createBrowserRouter,
    RouterProvider,
    Navigate
} from "react-router-dom";

const router = createBrowserRouter([
    {   
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "",
                element: <Navigate to="auth/sign-in" replace />
                },
        ]
    },
    {   
        path: "/auth",
        element: <Layout />,
        children: [

            {
                path: "activate/email/:uid",
                element: <Activate />
                },
            {
                path: "password-forgot/change/:uid",
                element: <PasswordForgotChange />
                },
            {
                path: "password-forgot/modified",
                element: <PasswordForgotModified />
                },
            {
                path: "password-forgot/",
                element: <PasswordForgot />
                },
            {
                path: "password-forgot/sent",
                element: <PasswordForgotSent />
                },
            {
                path: "sign-in",
                element: <Signin />
                },
            {
                path: "sign-up",
                element: <Signup />
                },
        ],
    },
    {   
        path: "/bookings/",
        element: <IconsLayout />,
        children: [
            {
                path: "",
                element: <BookingIndex />
                },
        ]
    },
    {   
        path: "/profile/",
        element: <IconsLayout />,
        children: [
            {
                path: "",
                element: <ProfileIndex />
                },
            {
                path: "edit/name",
                element: <ProfileNameEdit />
                },
            {
                path: "edit/email",
                element: <ProfileEmailEdit />
                },
            {
                path: "edit/phone",
                element: <ProfilePhoneEdit />
                },
            {
                path: "activate/new-email/:uid",
                element: <ActivateNewEmail />
                },
            {
                path: "activate/new-phone/:uid",
                element: <ActivateNewPhone />
                },
        ]
    },
    {
        path: "*",
        element: <NotFound />
        }
]);

function App() {

    return (
        <RouterProvider router={router} />
        );
}

export default App;
