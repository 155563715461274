import { useEffect, useState } from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// icons
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ChecklistIcon from '@mui/icons-material/Checklist';

const DrawerMenu = (props) => {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(null);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);

    return (
        <>
        <List sx={{p:0}}>
            <ListItem key="menu-bookings" disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={() => navigate('/bookings')}
                sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
                }}
            >
                    <ListItemIcon
                    onClick={() => navigate('/bookings')}
                    sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : 'auto',
                    justifyContent: 'center',
                    }}
                >
                        <ChecklistIcon />
                    </ListItemIcon>
                    <ListItemText primary="Mis Reservas" sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key="menu-user-profile" disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={() => navigate('/profile/')}
                sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
                }}
            >
                    <ListItemIcon
                    onClick={() => navigate('/profile/')}
                    sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : 'auto',
                    justifyContent: 'center',
                    }}
                >
                        <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Mis datos" sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
        </List>

</>
    );
}
export default DrawerMenu;
