import {useState, useEffect} from 'react';
import {config} from '../constants/config';
import {jwtValidate, jwtRefresh} from '../constants/api/jwt';

export const useAuthenticated = () => {
    const [loaded, setLoaded] = useState(false);
    const [valid, setValid] = useState(true);
    const [lock, setLock] = useState(false);
    useEffect(() => {
        const refresh = (IID) => {
            setLock(true);
            jwtRefresh().then((data) => {
                if(data.hasOwnProperty('status') && data.status === 'success') {
                    setValid(true);
                } else {
                    setValid(false);
                    clearInterval(IID);
                }
                setLock(false);
            }).catch((error) => {
                setValid(false);
                clearInterval(IID);
                setLock(false);
            })
        }
        const validate = (IID) => {
            jwtValidate().then((data) => {
                if (lock) return;
                if(data.hasOwnProperty('status') && data.status === 'success') {
                    setValid(true);
                } else {
                    setValid(false);
                    clearInterval(IID);
                }
            }).catch((error) => {
                setValid(false);
                clearInterval(IID);
            });
        }
        if(!loaded) {
            console.log('useAuthenticated loaded.');
            setLoaded(true);
            validate()
            return;
        }
        const refreshIDx = setInterval(() => refresh(refreshIDx), config.useauthenticated_refresh_interval);
        const validateIDx = setInterval(() => validate(validateIDx), config.useauthenticated_validate_interval);
        return () => { if(refreshIDx) { clearInterval(refreshIDx); }; if(validateIDx) {clearInterval(validateIDx); }; return valid; }
    }, [loaded, valid, lock]);

    return valid;
}
