import {Paper, List, ListItem, ListItemText, Box, Stack, Link, Chip} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const ProfileName = (props) => {
    const {phone, new_phone} = props;
    const active = (new_phone === null);

    return (
        <>
        <Paper>
            <Stack>
                <Box>
                    <Stack direction="row">
                        <Box display="flex" justifyContent="flex-start" sx={{ width: "100%" }}>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Teléfono" secondary={phone} />
                                </ListItem>
                                {new_phone !== null && (
                                <ListItem>
                                    <ListItemText primary="Nuevo Teléfono" secondary={new_phone} />
                                </ListItem>
                                )}
                            </List>
                        </Box>
                        <Box display="flex" justifyContent="flex-start">
                            <Link href="edit/phone" color="primary" sx={{p:0, mt:2, mr:2}}>
                                <EditIcon sx={{ height: 20, width: 20 }} />
                            </Link>
                        </Box>
                    </Stack>
                </Box>
                <Box display="flex" justifyContent="flex-end" sx={{pb:2, pr:2}}>
                    <Chip label={active ? 'Activo' : 'Inactivo'} color={active ? 'primary' : 'error'} sx={{width:80}} />
                </Box>
            </Stack>
        </Paper>
</>
    );
}

export default ProfileName;
