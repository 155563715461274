import {Box, Pagination} from '@mui/material';
import {retrieveBookingsByPage} from './constants/api';

const BookingPagination = (props) => {
    const {pagination, setBookings} = props;
    return (<>
        {pagination.pages > 1 && (<Box display="flex" justifyContent="center" alignItems="center" sx={{pt:2}}>
            <Pagination count={pagination.pages} onChange={(event, _page) => retrieveBookingsByPage(_page).then((data) => setBookings(data.results))} variant="outlined" shape="rounded"  />
            </Box>)}
    </>);
}

export default BookingPagination;
