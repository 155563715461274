import {Paper, Typography} from '@mui/material';
import {monthsSpanish} from '../../constants/data';

const BookingMonthDataHeader = (props) => {
    const {booking_key, booking_key_index, page} = props;
    return (<Paper key={"bip-"+page+"-"+booking_key_index} sx={{mt:(booking_key_index > 0) ? 4: 0, p: 2, mb:2, background: 'linear-gradient(to right top, #00d4ff, #0a1172)', color: 'common.white'}}>
        <Typography align="right" variant="h5">{monthsSpanish[parseInt(booking_key.split('-')[1])-1]} {booking_key.split('-')[0]}</Typography>
        </Paper>);
}

export default BookingMonthDataHeader;
