import {Paper, List, ListItem, ListItemText, Box, Link, Stack} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const ProfileName = (props) => {
    const {first_name, last_name} = props;

    return (
        <>
        <Paper>
            <Stack direction="row">
                <Box display="flex" justifyContent="flex-start" sx={{ width: "100%" }}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Nombre" secondary={first_name} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Apellido" secondary={last_name} />
                        </ListItem>
                    </List>
                </Box>
                <Box display="flex" justifyContent="flex-start">
                    <Link href="edit/name" color="primary" sx={{p:0, mt:2, mr:2}}>
                        <EditIcon sx={{ height: 20, width: 20 }} />
                    </Link>
                </Box>
            </Stack>
        </Paper>
</>
    );
}

export default ProfileName;
