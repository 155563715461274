import {Paper, List, ListItem, ListItemText, Box, Link, Stack, Chip} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const ProfileName = (props) => {
    const {email, new_email} = props;
    const active = (new_email === null);
    return (
        <>
        <Paper>
            <Stack>
                <Stack direction="row">
                    <Box display="flex" justifyContent="flex-start" sx={{ width: "100%" }}>
                        <List>
                            <ListItem>
                                <ListItemText primary="Email" secondary={email} />
                            </ListItem>
                            {new_email !== null && (
                            <ListItem>
                                <ListItemText primary="Nuevo Email" secondary={new_email} />
                            </ListItem>
                            )}
                        </List>
                    </Box>
                    <Box display="flex" justifyContent="flex-start">
                        <Link href="edit/email" color="primary" sx={{p:0, mt:2, mr:2}}>
                            <EditIcon sx={{ height: 20, width: 20 }} />
                        </Link>
                    </Box>
                </Stack>
                <Box display="flex" justifyContent="flex-end" sx={{pb:2, pr:2}}>
                    <Chip label={active ? 'Activo' : 'Inactivo'} color={active ? 'primary' : 'error'} sx={{width:80}} />
                </Box>
            </Stack>
    </Paper>
    </>
    );
}

export default ProfileName;
