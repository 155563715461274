import {useEffect, useState} from 'react';
import {Grid, Paper, Box, Stack, Avatar, Typography} from '@mui/material';
import BookingIndexItemDetails from './IndexItemDetails';

const BookingIndexItem = (props) => {
    const [loaded, setLoaded] = useState(false); 
    const {booking} = props;
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);
    return (<>
        {loaded && (<Grid item xs={12}><Paper sx={{ p:2 }}>
            <Stack direction="row">
                <Box display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{width: "50%"}}>
                <Stack>
                    <Avatar alt="Avatar" sx={{width: "150px", height: "150px", p:2}} src={booking.shop_avatar} />
                    <Typography variant="body2" align="center">
                        {booking.shop_name}
                    </Typography>
                </Stack>
            </Box>
            <BookingIndexItemDetails booking={props.booking} />
        </Stack>
        </Paper></Grid>)}
    </>);
}

export default BookingIndexItem;
